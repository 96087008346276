import React from "react"
import eventImg from "../images/event.png"

import Button from "./button"

const Event = () => (
    <section className="event">
        <div className="event-img">
            <img src={eventImg} alt="Event" />
        </div>
        <div className="band-top">
            <div className="top-contents">
                <p className="eyebrow-text">Webinar</p>
                <h2>Urgent Means Project Virtual Town Hall</h2>
                <p className="paragraph-text">CareAdvisors recently collaborated with Congressman Danny K. Davis representing the 7th District of Illinois and an esteemed panel of Chicago's leading public health advocates to discuss patient access issues in Chicagoland. Terry Mason, MD, of WVON 1690 AM Radio, served as moderator.</p>
            </div>
        </div>
        <div className="band-bottom">
            <div className="bottom-contents">
                <Button buttonText="Watch Video"/>
            </div>
        </div>
    </section>
)

export default Event;