import React from "react"

import Layout from "../components/layout"
// import SEO from "../components/seo"
import Hero from "../components/hero"
import Services from "../components/services"
import Event from "../components/event"
import Solutions from "../components/solutions"
import Products from "../components/products"
import Team from "../components/team"
import Contact from "../components/contact"

const IndexPage = () => (
    <Layout>
        {/* <SEO title="Home" /> */}
        <Hero/>
        <Services/>
        <Event/>
        <Solutions/>
        <Products/>
        <Team/>
        <Contact/>
    </Layout>
)

export default IndexPage
