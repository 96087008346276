import React from "react"
import chrisImg from "../images/image-chris.svg"
import dawnImg from "../images/image-dawn.svg"
import pradeepImg from "../images/image-pradeep.svg"

import Button from "./button"
import Teammate from "./teammate"

const Team = () => {
  const cardData = [
    {
        key: "chris",
        img: chrisImg,
        name: "Chris Gay",
        role: "CEO of CareAdvisors",
        bio:
        "Chris co-founded CareAdvisors and has grown it to become a leader in social care management, working with major hospitals and health plans. He previously built one of the largest healthcare navigation programs, helping over one million low-income individuals enroll in social service benefit programs.",
        linkedIn: null,
    },
    {
        key: "dawn",
        img: dawnImg,
        name: "Dawn Gay",
        role: "Executive Director of CareAdvisors",
        bio:
        "Dawn has over 25 years of experience across diversified healthcare settings. She previously served as Executive Director of the I.B. of T. Local 705, Teamsters Health Center where she fostered an interest in building values-driven relationships to serve vulnerable populations and address social determinants of health.",
        linkedIn: null,
    },
    {
        key: "pradeep",
        img: pradeepImg,
        name: "Pradeep Keshary",
        role: "CTO of CareAdvisors",
        bio:
        "Pradeep is a healthcare technologist, entrepreneur, and community volunteer with a decade of experience building widely adopted web and mobile applications. At CareAdvisors, Pradeep leads the engineering team in building the first unifying social service infrastructure that will empower community service heroes.",
        linkedIn: null,
    },
  ]

  const cards = cardData.map(solution => {
    const { key, img, name, role, bio, linkedIn } = solution

    return <Teammate key={key} img={img} name={name} role={role} bio={bio} linkedIn={linkedIn} />
  })

  return (
    <section className="team" id="team">
      <div className="inner-content">
        <p className="eyebrow-text">TEAM</p>
        <h2>Expert Teams to Help,<br/>Every Step of the Journey</h2>
        <p className="paragraph-text">
          CareAdvisors is a fast-moving, minority-owned technology company that
          helps millions of individuals gain rapid access to social service
          benefits. Our founding team has more than 10 years of experience helping
          patients gain access to social service benefits and vital healthcare in
          Chicago.
        </p>
        <h3>Meet Our Team</h3>
        <div className="teammate-cards">{cards}</div>
        <Button buttonText="Join Us Now" />
      </div>
    </section>
  )
}

export default Team
