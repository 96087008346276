import React from "react"
import revenueImg from "../images/image-revenue.svg"
import retentionImg from "../images/image-robot.svg"
import automationImg from "../images/image-automation.svg"
import matchingImg from "../images/image-matching.svg"

import Button from "./button"
import Solution from "./solution"

const Solutions = () => {
  const cardData = [
    {
      key: "revenue",
      title: "Revenue improvement of up to",
      metric: "$2M",
      titleExtension: "from Medicaid and Medicare wellness programs",
      description:
        "Our Active Payments solution helps you identify high-risk patients and rapidly enroll them in social service benefits and care coordination programs to drive new revenue from value-based payments.",
      img: revenueImg,
    },
    {
      key: "retention",
      title: "Improve retention rates by",
      metric: "50%",
      titleExtension: "for existing plan members",
      description:
        "By keeping members enrolled in your health plan, you can ensure the continuity of care stays intact and members maintain access to preventive care.",
      img: retentionImg,
    },
    {
      key: "automation",
      title: "Automate the enrollment process to increase capacity for a",
      metric: "10x",
      titleExtension: "improvement in case management efficiency",
      description:
        "Increasing the capacity of your care coordinators and social workers allows them to focus on the delivery of care and reaching more patients.",
      img: automationImg,
    },
    {
      key: "reduction",
      title: "Reduce time spent building data-driven strategies by",
      metric: "90%",
      titleExtension: "for social care management",
      description:
        "Bring data-driven social care management strategies to your CBO quickly, with access to our data resources for driving new revenue and grant dollars for programmatic services.",
      img: matchingImg,
    },
  ]

  const cards = cardData.map((solution, index) => {
    const { key, title, metric, titleExtension, description, img } = solution

    return (
      <Solution
        key={key}
        title={title}
        metric={metric}
        titleExtension={titleExtension}
        description={description}
        img={img}
        textPosition={index % 2 ? "left" : "right"}
      />
    )
  })

  return (
    <section className="solutions" id="solutions">
      <div className="inner-content">
        <p className="eyebrow-text">SOLUTIONS</p>
        <h2 className="solutions-caption">
          One Location for Connecting Communities to Care
        </h2>
        <div className="solution-cards">{cards}</div>
        <Button buttonText="Request Free Demo" />
      </div>
    </section>
  )
}

export default Solutions
