import React from 'react'

const Banner = ({mediaType, mediaDuration, mediaText, isFormPage}) => (
    <aside className={`${isFormPage ? " banner-form" : "banner"}`}>
        <div className="media-attributes">
            <h4 className={`media-type-${mediaType}`}>{mediaType}</h4>
            <h4 className="media-duration">{mediaDuration}</h4>
        </div>
        <h1>{mediaText}</h1>
    </aside>
)

export default Banner