import React from "react"
import infographicImg from "../images/CASS.png"

import Button from "./button"

const Infographic = () => (
    <section className="infographic">
        <div className="infographic-img">
            <img src={infographicImg} alt="Infographic" />
        </div>
        <div className="band-top">
            <div className="top-contents">
                <p className="eyebrow-text">Infographic</p>
                <h2>Urgent Means Project Survey Insights</h2>
                <p className="paragraph-text">Constricted access to social services (CASS) is a very real concern for vulnerable populations. Our infographic highlights key results from a CareAdvisors survey of Chicago-area social workers who share the impact COVID-19 has had on CASS.</p>
            </div>
        </div>
        <div className="band-bottom">
            <div className="bottom-contents">
                <Button buttonText="Download Infographic"/>
            </div>
        </div>
    </section>
)

export default Infographic;