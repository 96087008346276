import HubspotForm from "react-hubspot-form"
import React from "react"
import requestImg from "../images/kv-demopage.png"
import automationIcon from "../images/icon-automation.svg"
import resourceIcon from "../images/icon-resource.svg"
import growthIcon from "../images/icon-growth.svg"

import Layout, { TopCTAStateContext } from "../components/layout"

const RequestPage = () => {
  const cardData = [
    {
      key: "automation",
      icon: automationIcon,
      text:
        "Social Care Automation to help you identify the specific social needs of your patients and connect them to the best resources quickly",
    },
    {
      key: "resource",
      icon: resourceIcon,
      text:
        "Access to our Community Care Network with resources that prioritize closing the loop on care",
    },
    {
      key: "growth",
      icon: growthIcon,
      text:
        "Automated enrollment for new and existing members to maximize plan retention and reduce churn",
    },
  ]

  const cards = cardData.map(request => {
    const { key, icon, text } = request

    return (
      <div key={key}>
        <div className="request-card">
          <img src={icon} alt="Request Icon" />
          <h4>{text}</h4>
        </div>
        <hr />
      </div>
    )
  })

  return (
    <Layout>
      <TopCTAStateContext.Consumer>
        {(value) => {
          const imageClassName = value.isCTAVisible ? 'banner-img' : 'banner-img cta-closed';
          return (
            <>
              <div className={ imageClassName }>
                <h1>Social Care Automation for Community Health</h1>
                <img src={requestImg} alt="Request Image" />
              </div>
              <section className="request">
                <h1>Request Your Demo</h1>
                <div className="hubspot-form">
                  <HubspotForm
                    className="request-form"
                    portalId="4891903"
                    formId="4fa178ff-bb59-44eb-acc8-14602ecf4934"
                  />
                </div>
                <div className="request-text">
                  <p className="paragraph-text">
                    Connect with us to see why CareAdvisors is the preferred platform
                    for hospitals and health plans for Social Care Automation for Community Health.
                  </p>
                  <p className="body-text">Request your demo to learn more about:</p>
                  <hr />
                  {cards}
                </div>
              </section>
            </>
          )
        }}
      </TopCTAStateContext.Consumer>
    </Layout>
  )
}

export default RequestPage
